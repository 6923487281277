import React, { useEffect } from "react";
import { Link } from 'gatsby';
import { CONSTANTS } from '@Constants/Constants';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { activatePill } from '../../utils';

export default function HomeCharger({mobile = false}) {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.timeline({
            scrollTrigger: {
                trigger: '.chargersObserver4',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                }
            }
        });
        gsap.timeline({
            scrollTrigger: {
                trigger: '.chargersObserver5',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.chargers.id);
                }
            }
        });
    },[]);

    return (
        <div className={mobile ? "chargers mobile" : "chargers"}>
            <div className="chargers_sections">
                <section className="fullscreen home-charger observe chargersObserver4 horizontal-section" data-section-type={CONSTANTS.sectionType.light}>
                    <div className="container">
                        <div className="home-charger_content first">
                            <h2 className="home-charger_heading">Instaluj <br />
                            stację ładowania <br />
                            w&nbsp;domu</h2>
                            <p className="home-charger_paragraph">Kup domową stację ładowania do swojego<br /> samochodu. Oszczędzaj. Zarabiaj.</p>
                            <div className="home-charger_images">
                                <img className="home-charger_img home-charger_img-1" src="/images/chargers/charger-02.jpg" alt="Nexity - charger 1" />
                                <img className="home-charger_img home-charger_img-2" src="/images/chargers/charger-03.jpg" alt="Nexity - charger 2" />
                            </div>
                            <button className="btn btn--secondary home-charger_btn" title="Instaluj teraz">
                                <Link to={'/under-construction'}>Instaluj teraz</Link>
                            </button>
                        </div>
                    </div>
                </section>
                <section className="fullscreen company-charger home-charger observe chargersObserver5 horizontal-section" data-section-type={CONSTANTS.sectionType.light}>
                    <div className="container">
                        <div className="home-charger_content second">
                            <h2 className="company-charger_heading">Instaluj <br />
                            stację ładowania <br />
                            w&nbsp;firmie</h2>
                            <p className="company-charger_paragraph">Zaprojektujemy infrastrukturę dla&nbsp;Twojego<br />biznesu. Oszczędzaj. Zarabiaj.</p>
                            <div className="company-charger_images">
                                <img className="company-charger_img" src="/images/chargers/charger-04.jpg" alt="Nexity - charger 3" />
                            </div>
                            <button className="btn btn--secondary company-charger_btn" title="Instaluj teraz">
                                <Link to={'/under-construction'}>Instaluj teraz</Link>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}