import React, { useEffect } from "react";
import PlayStore from '@Components/MobileStores/PlayStore';
import AppleStore from '@Components/MobileStores/AppleStore';
import gsap, { Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HomeCharger from '../HomeCharger/HomeCharger';
import { CONSTANTS } from '@Constants/Constants';

function handleAnimationMobile() {
    gsap.registerPlugin(ScrollTrigger);

    const sectionTl = gsap.timeline({
        scrollTrigger: {
            trigger: ".chargers.mobile",  
            start: "top top",
            end: "+=200%",
            scrub: 0.23,
            pin: true
        },
    })

    sectionTl.fromTo(
                ".chargers_sections",
                { x: '0%', ease: "none" },
                { x: '-50%', ease: "none", duration: 1 }
            );
}

function handleAnimation() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.set('.install-app', { opacity: 1 });

    const sectionTl = gsap.timeline({
        scrollTrigger: {
            trigger: ".install-app",  
            start: "top top",
            end: "+=800%",
            scrub: 0.23,
            pin: true
        },
    })

    sectionTl.fromTo(".install-app_heading", {opacity: 0, y: '60px' }, {opacity: 1, y: '0px', duration: 0.6 })
    .fromTo(".install-app_paragraph", {opacity: 0, y: '60px' }, {opacity: 1, y: '0px', duration: 0.6 }, '-=0.3')
    .fromTo(".install-app_stores", {opacity: 0, y: '60px' }, {opacity: 1, y: '0px', duration: 0.6 }, '-=0.3')
    .fromTo(".install-app_tablet", {scale: 1, opacity: 1}, {scale: 3, opacity: 1, ease: Power4.easeOut, duration: 1 }, '+=0.2')
    .fromTo(".overlay--white", { opacity: 0 }, { opacity: 1, ease: Power4.easeOut, duration: 0.3 }, '-=1')
    .fromTo(".chargers", { scale: 0.4, x:'7.5%', y: '15%' }, { x: 0, y: 0, scale: 1, duration: 1 }, '-=1')
    .fromTo(".chargers", { opacity: 0 }, { opacity: 1, duration: 1 }, '-=0.9')
    .set('.install-app_tablet-area', { visibility: 'hidden' });

    sectionTl.fromTo(
                ".chargers_sections",
                { x: '0%', ease: "none" },
                { x: '-50%', ease: "none", duration: 1 },
                '+=0.3'
            )
            .fromTo(
                ".company-charger_heading",
                { opacity: 0 },
                { opacity: 1, duration: 0.6 }
            )
            .fromTo(".company-charger_paragraph",
                { opacity: 0 }, 
                { opacity: 1, duration: 0.6 },
                '-=0.4'
            )
            .fromTo('.company-charger_btn',
                { opacity: 0 },
                { opacity: 1, duration: 1 },
                '-=0.4'
            );
}

export default function InstallApp() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.matchMedia({
            "(max-width: 1024px)": function() {
                handleAnimationMobile();
            },
            "(min-width: 1025px)": function() {
                handleAnimation();
            }
        });
    }, []);

    return (
        <>
            <div className="fullscreen install-app observe chargersObserver" data-section-type={CONSTANTS.sectionType.dark}>
                <img className="install-app_bg" src="/images/tablet_bg.jpg" alt="tablet" />
                <div className="container">
                    <div className="text-white install-app_content">
                        <h2 className="install-app_heading">Instaluj aplikację i&nbsp;jedź</h2>
                        <p className="install-app_paragraph">Zainstaluj darmową aplikację na swoim smartfonie i&nbsp;korzystaj z&nbsp;tysięcy ładowarek w&nbsp;całej Europie.</p>
                        <span className="install-app_stores stores">
                            <PlayStore />
                            <AppleStore />
                        </span>
                    </div>
                    <div className="install-app_tablet-area">
                        <div className="install-app_tablet">
                            <img className="install-app_tablet-frame" src="/images/tablet.png" alt="tablet" />
                            <img className="install-app_tablet-frame--mobile" src="/images/tablet--mobile.png" alt="tablet" />
                            <video className="install-app_tablet-inner" playsInline autoPlay muted loop>
                                <source src="videos/full-length.mp4" type="video/mp4" />
                            </video>
                            <div className="overlay overlay--white"></div>
                        </div>
                    </div>
                    <span className="install-app_stores--mobile stores">
                        <PlayStore />
                        <AppleStore />
                    </span>
                </div>
                <HomeCharger mobile={false}/>
            </div>
            <HomeCharger mobile={true}/>
        </>
    )
}